import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "../common/api";
import {
  ACCOUNT_DETAILS,
  BATTEL_DETAILS,
  BATTEL_LIST,
  BATTEL_LIST_2,
  BATTEL_LIST_USERS,
  CREATE_ACCOUNT,
  GET_ADMIN_AC,
  GET_FRIEND_REQ_LIST,
  GET_fUND_REQ,
  GET_MESSAGE,
  GET_METRICS,
  GET_NOTIFICATION,
  GET_SETTING,
  GET_TRANSATION,
  GET_USER_LIST,
  GET_WITHDRWAL_LIST,
  LOADING,

  // SELLER_LIST,
  // DESHBOARD_STATE,
  PROFILE,
} from "../common/constant";
const Token = require("../../Utils/Auth/Token");

const loading = (data) => {
  return { type: LOADING, payload: data };
};

const profileAction = (data) => {
  return { type: PROFILE, payload: data };
};
const battelListAction = (data) => {
  return { type: BATTEL_LIST, payload: data };
};
const battelDetalsAction = (data) => {
  return { type: BATTEL_DETAILS, payload: data };
};
const battelListUsersAction = (data) => {
  return { type: BATTEL_LIST_USERS, payload: data };
};
const createAccountAction = (data) => {
  return { type: CREATE_ACCOUNT, payload: data };
};
const accoutdetailsAction = (data) => {
  return { type: ACCOUNT_DETAILS, payload: data };
};
const gettransactionAction = (data) => {
  return { type: GET_TRANSATION, payload: data };
};
const getsettingAction = (data) => {
  return { type: GET_SETTING, payload: data };
};
const getwithdrwalListAction = (data) => {
  return { type: GET_WITHDRWAL_LIST, payload: data };
};
const searchuserforfriendAction = (data) => {
  return { type: GET_USER_LIST, payload: data };
};
const frindrequestListAction = (data) => {
  return { type: GET_FRIEND_REQ_LIST, payload: data };
};
const metricsUserAction = (data) => {
  return { type: GET_METRICS, payload: data };
};
const genotificationrAction = (data) => {
  return { type: GET_NOTIFICATION, payload: data };
};
const getadminAcAction = (data) => {
  return { type: GET_ADMIN_AC, payload: data };
};
const getfundreqAction = (data) => {
  return { type: GET_fUND_REQ, payload: data };
};

const messageUserAction = (data) => {
  return { type: GET_MESSAGE, payload: data };
};
const battelList2Action = (data) => {
  return { type: BATTEL_LIST_2, payload: data };
};

export const getbattellist2 = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getbattellist2}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(battelList2Action(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getmessageuser = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getmessageuser}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(messageUserAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        dispatch(frindrequestListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const addfundreqestListuser = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.addfundreqestListuser}/${data}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(getfundreqAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        // dispatch(genotificationrAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getaccountListdetails = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getaccountListdetails}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(getadminAcAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        // dispatch(genotificationrAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const notificationseen = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.notificationseen}`,
        // data
        config
      );
      if (response?.status == 200) {
        // dispatch(genotificationrAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        // dispatch(genotificationrAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getusernotification = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getusernotification}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(genotificationrAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        dispatch(genotificationrAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const friendList = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.friendList}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(frindrequestListAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        dispatch(frindrequestListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const metricsUser = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.metricsUser}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(metricsUserAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        // dispatch(frindrequestListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const frindrequestList = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.frindrequestList}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(frindrequestListAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        dispatch(frindrequestListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const Acceptreq = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.Acceptreq}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);

        dispatch(frindrequestListAction(response?.data?.data));
        // dispatch(createAccountAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const searchuserforfriend = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.searchuserforfriend}`,
        data,
        config
      );
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        dispatch(searchuserforfriendAction(response?.data?.data));
        // dispatch(createAccountAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const sendfrindreq = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.sendfrindreq}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);

        // dispatch(searchuserforfriendAction(response?.data?.data));
        // dispatch(createAccountAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getwithdrawallist = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getwithdrawallist}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(getwithdrwalListAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getsetting = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsetting}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(getsettingAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getaccuontdetails = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getaccuontdetails}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(accoutdetailsAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const withdrawaluserAmount = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.withdrawaluserAmount}`,
        data,
        config
      );
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        alert(response?.data?.message);
        navigate("/");
        const responsed = await api.get(
          `${URL.getprofile}`,
          // data
          config
        );

        dispatch(profileAction(responsed?.data?.data));
        // dispatch(createAccountAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createaccount = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createaccount}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        navigate("/");
        const responsed = await api.get(
          `${URL.getprofile}`,
          // data
          config
        );

        dispatch(profileAction(responsed?.data?.data));
        // dispatch(createAccountAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateaccount = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.updateaccount}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        navigate("/");
        // const responsed = await api.get(
        //   `${URL.getprofile}`,
        //   // data
        //   config
        // );

        dispatch(accoutdetailsAction(response?.data?.data));
        // dispatch(createAccountAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const cencelBettel = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.cencelBettel}`, data, config);

      if (response?.status == 200) {
        toast.success(response?.data?.message);
        dispatch(battelListAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const login = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.login}`,
        data
        // config
      );

      if (response?.status == 200) {
        toast.success(response?.data?.message);

        // Token.setToken(response?.data?.token);

        // const lsValue = JSON.stringify(response?.data?.data);
        // Token.setUserDetail(lsValue);
        // setTimeout(navigate("/"), 3000);
        dispatch(loading(false));
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        return response?.data;
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const otpVerify = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.otpVerify}`,
        data
        // config
      );
      if (response?.status == 200) {
        toast.success(response?.data?.message);

        Token.setToken(response?.data?.token);

        const lsValue = JSON.stringify(response?.data?.data);
        Token.setUserDetail(lsValue);
        setTimeout(navigate("/"), 3000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createbattel = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createbattel}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        dispatch(battelListAction(response?.data?.data));
        // dispatch(profileAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const addFound = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addFound}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        dispatch(profileAction(response?.data?.data));
        navigate("/");
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const fundTransfer = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.fundTransfer}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        dispatch(profileAction(response?.data?.data));
        navigate("/");
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const addFound2 = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addFound2}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        dispatch(profileAction(response?.data?.data));
        navigate("/");
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const cencelBettelAfterJoin = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.cencelBettelAfterJoin}`,
        data,
        config
      );
      if (response?.status == 200) {
        // toast.success(response?.data?.message);
        // dispatch(battelDetalsAction(response?.data?.data));

        // navigate("/ludo-Classic");
        
        if (response?.data?.data?.batteltype == "classicmanual") {
          navigate("/ludo-Classic");
        } else if (response?.data?.data?.batteltype == "classicauto") {
          navigate("/ludo-Classic-auto");
        } else if (response?.data?.data?.batteltype == "popularauto") {
          navigate("/ludo-popular");
        } else {
          navigate("/snake-and-ladder");
        }
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const submitWinner = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.submitWinner}`, data, config);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);
        // dispatch(battelDetalsAction(response?.data?.data));

        if (response?.data?.data?.batteltype == "classicmanual") {
          navigate("/ludo-Classic");
        } else if (response?.data?.data?.batteltype == "classicauto") {
          navigate("/ludo-Classic-auto");
        } else if (response?.data?.data?.batteltype == "popularauto") {
          navigate("/ludo-popular");
        } else {
          navigate("/snake-and-ladder");
        }

        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getbattelDetails = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getbattelDetails}`, data, config);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);
        dispatch(battelDetalsAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const joinbettel = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.joinbettel}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        dispatch(battelListAction(response?.data?.data));
        // dispatch(profileAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const addfundreqest = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addfundreqest}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        // dispatch(battelDetalsAction(response?.data?.data));
        // dispatch(profileAction(response?.data?.data));
        navigate("/fund-request");
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const startbettel2 = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.startbettel2}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        dispatch(battelDetalsAction(response?.data?.data));
        // dispatch(profileAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const startbettel = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.startbettel}`, data, config);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        dispatch(battelDetalsAction(response?.data?.data));
        // dispatch(profileAction(response?.data?.data));
        dispatch(loading(false));
      } else {
        // console.log(response?.data?.data);
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const getbattellist = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getbattellist}/${data}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(battelListAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserTransation = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserTransation}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(gettransactionAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getbattellistUser = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getbattellistUser}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(battelListUsersAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getprofile = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getprofile}`,
        // data
        config
      );
      if (response?.status == 200) {
        dispatch(profileAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const edituserId = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.edituserId}`, data, config);
      if (response?.status == 200) {
        dispatch(profileAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const editprofileImage = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.editprofileImage}`, data, config);
      if (response?.status == 200) {
        dispatch(profileAction(response?.data?.data));

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
